import React from 'react';
import Layout from "../../components/Layout/Layout";
import HomeMobile from "./HomeMobile";
import AboutMobile from "./AboutMobile";
import ServicesMobile from "./ServicesMobile";
import TeamMobile from "./TeamMobile";
import PortfolioMobile from "./PortfolioMobile";
import ClientsMobile from "./ClientsMobile";
import ReviewsMobile from "./ReviewsMobile";
import ContactMobile from "./ContactMobile";
import ApplicationMobile from "./ApplicationMobile";
import VideoProductionMobile from "./VideoProductionMobile";

const Mobile = () => {
    return (
        <Layout>
            <HomeMobile/>
            <AboutMobile/>
            <ServicesMobile/>
            <TeamMobile/>
            <PortfolioMobile/>
            <VideoProductionMobile/>
            <ClientsMobile/>
            <ReviewsMobile/>
            <ContactMobile/>
            <ApplicationMobile/>
        </Layout>
    );
};

export default Mobile;