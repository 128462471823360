export const UZ = {

    VideoProduction: {
        title1: "Video-",
        title2: "production",
        commit1: "Mahsulotingizni reklama qilish",
        commit2: "yorqin va qiziqarli bo'ladi.",
        buttonText: "Batafsil"
    },

    Reviews: {
        pageTitle1: "MIJOZLAR",
        pageTitle2: "NIMA",
        pageTitle3: "DEYDI",
        description1: "Qisqa vaqt ichida biz mahalliy bozorda mahsuldorlik va mijozlarning xabardorligi bo‘yicha real natijalarni ko‘rishga muvaffaq bo‘ldik. Puxta o‘ylangan strategiya va bankimizning zarur imidjini yaratish tufayli biz maqsadli auditoriyamiz orasida tobora ommalashib bormoqdamiz.",
        description2: "FocusON Tech jamoasi bilan ishlash ajoyib tajriba bo'ldi. Ular o'z biznesini yaxshi biladigan juda professional va samimiy odamlardir. Men ushbu kompaniya bilan ishlash orqali biznesim uchun katta qiymatga ega bo'ldim.",
        description3: "FocusON Tech o'tmishda BPO himoyachilari sifatida ishlagan kompaniyalar orasida eng vakolatli va mas'uliyatli hamkorlardan biri bo'lgan. Ular mukammal loyihalarni boshqarish va natijalarga yo'naltirilgan."
    },

    Portfolio:{
        title: "Portfolio",
        modal: {
            title: "Ko'rsatkichlar:",
            countTitle1: "Obunachilarning o'sishi",
            countTitle2: "ER",
            countTitle3: "Qamrab olish",
            countTitle4: "Profilga tashriflar",
            countTitle5: "Izohlar",
            countTitle6: "Ko'rishlar",
            link_text: "Akkauntga o'tish",
        },
        portfolioDescriptions: {
            description1: "Poytaxtning markazida joylashgan qandolatchilik / qahvaxona. Menyudagi arzon narxlar bilan uchrashuv joyi sifatida mashhur. Maqsad - brendni bilish, reklama qilish, sotishni oshirish. Loyihani boshqarish - 2 yildan ortiq.",
            description2: "Uyda tayyorlangan qulay ovqatlarni ishlab chiqarish va sotish. Maqsad - savdoni oshirish, brend xabardorligini oshirish. Loyihani boshqarish - 6 oy.",
            description3: "Qandolat fabrikasi. Maqsad - brendni tan olish, brendga sodiqlikni oshirish. Biz 2 yildan beri ishlaymiz.",
            description4: "Uy, sport va uyqu uchun ayollar trikotaj to'plamlarini o'z ishlab chiqarish va sotish. Maqsad - brendni tan olish, sotishni ko'paytirish.",
            description5: "GRANDPHARM dorixonalar tarmog‘i O‘zbekistondagi yetakchi dorixonalardan biridir. Maqsad brendni tan olishdir.",
            description6: "O'zbekistonda go'shtli delikates va kolbasa ishlab chiqaruvchisi. Maqsad - brend xabardorligi, brendga sodiqlikni oshirish.",
            description7: "Qadoqlangan sharbatlar va nektarlar ishlab chiqarish. Maqsad brendni tan olishdir. Loyihani boshqarish - 4 oy",
            description8: "O'zbekistonda TM VIKO premium sharbatlari va nektarlarini ishlab chiqarish. Maqsad brendni tan olishdir. 4 oy davomida loyihani boshqarish.",
            description9: "Avtomobil ijarasi, ekonomdan premium sinfgacha. Maqsad - brendni tan olish, sotishni ko'paytirish. 2 yil davomida loyihani boshqarish.",
            description10: "Piza universiteti Xalqaro italyan universitetining Toshkentdagi filiali hisoblanadi. Maqsad brendni tan olish va talabalarni bakalavr darajasiga jalb qilishdir. Loyihani boshqarish - 8 oy.",
        }
    },

    Services: {
        title1: "BIZ QILA",
        title2: "OLAMIZ",
        buttonText: "Batafsil",
        service1: {
            name: "Ijtimoiy tarmoqlarda reklama",
            description: "Biz ijtimoiy tarmoqlar orqali kompaniyalarning samarali reklamasini rivojlantirishni taklif qilamiz. Biz to'g'ridan-to'g'ri bozorda brend xabardorligi ustida ishlaymiz."
        },
        service2: {
            name: "Veb-saytlarni ishlab chiqish",
            description: "Sizning biznesingiz uchun yuqori sifatli va ijodiy veb-sayt yaratish. Biz zamonaviy dizayn, yuqori sifatli kod va qulay navigatsiyani taklif etamiz."
        },
        service3: {
            name: "Dizayn va uslubni rivojlantirish",
            description: "Sizning kompaniyangizning bozorda \"yuzini\" shakllantirish uchun biz noyob brend nomi, logotipi, yo'riqnomasi va brend kitobini ishlab chiqamiz."
        },
        service4: {
            name: "Video va fotosessiya yaratish",
            description: "Interaktiv videolarni yaratish orqali kompaniyaning noyob imidjini yarating va keng auditoriyani jalb qiling"
        }
    },

    About: {
        commit1: "Faoliyatimiz boshlanganidan buyon biz 100 dan ortiq mahalliy va xorijiy kompaniyalar bilan hamkorlik qilishga muvaffaq bo'ldik.",
        commit2: "Biz iste'molchilarning kompaniya haqida xabardorligini oshirish orqali korxonalarga ko'proq foyda va benuqson obro'ga ega bo'lishga yordam beramiz. Biz veb-trafikni jalb qilamiz va mijozlar bilan uzoq muddatli muvaffaqiyatli munosabatlar o'rnatamiz.",
        commit3: "Har bir loyiha uchun o'z sohasi bo'yicha ma'lum mutaxassislar ajratiladi. Maqsadni to'g'ri qo'yish va tuzilgan vazifadan keyin loyihani muvaffaqiyatli ilgari surish kalitidir.",
        commit4: "Biz biznesni ichkaridan o'rganishga, tahlil qilishga va keyingi harakatlarni rejalashtirishga harakat qilamiz.",
    },

    Home: {
        topTitle1: "MARKETING",
        topTitle2: "AGENTLIGI",
        buttonText: "KP ni Yuklash"
    },

    Navbar: {
        menu_link1: "Biz haqimizda",
        menu_link2: "Xizmatlar",
        menu_link9: "Video production",
        menu_link3: "Jamoa",
        menu_link4: "Portfolio",
        menu_link5: "Mijozlar",
        menu_link6: "Sharhlar",
        menu_link7: "Aloqa",
        menu_link8: "Ariza yuborish",
        address1: "Oʻzbekiston, Toshkent,",
        address2: "koʻch. Chust, 1. Office C-Space",
    },

    Team: {
        title1: "JANGGA",
        title2: "TAYYOR",
        team1 : {
            name: "MUHAMMAD ALI",
            position: "199-darajali boshliq",
        },
        team2 : {
            name: "MUNISA",
            position: "SMM Guru",
        },
        team3 : {
            name: "DAMIR",
            position: "Badiiy direktor",
        },
        team4 : {
            name: "IBRAGIM",
            position: "Kuchli VIDEOGRAFER",
        },
        team5 : {
            name: "MADINA",
            position: "SMM menejer va Kopirayter",
        },
        team6 : {
            name: "ZUXRIDDIN",
            position: "Loyihalar bo'yicha menejer",
        },
        team7: {
            name: "Bunyod",
            position: "SMM menejer",
        },
        team8: {
            name: "Timur",
            position: "Targetolog",
        },
        team9: {
            name: "Malika",
            position: "SMM menejer",
        },
        team10: {
            name: "Burxon",
            position: "Moushen-dizayner",
        },
    },

    Client: {
        title1: "BIZNING",
        title2: "MIJOZLAR",
    },

    Contact: {
        title1: "BIZNING",
        title2: "MANZIL",
        address: "Oʻzbekiston, Toshkent, koʻch. Chust, 1. Office C-Space"
    },

    Application: {
        title: "Fikr-mulohaza oling",
        form: {
            title: "Savollaringiz bormi?",
            name: {
                label: "Ism (majburiy)",
                placeholder: "Sizning ismingiz",
            },
            phone: {
                label: "Telefon (majburiy)",
                placeholder: "+998 ( __ ) __ __ __",
            },
            description: {
                label: "Sizning kompaniyangiz haqida bir oz:",
                placeholder: "O'zingiz xohlagan vazifani tasvirlab bering*"
            },
            buttonText: "Yuborish",
            toastify: {
                successMessage: "Arizangiz muvaffaqiyatli yuborildi!!! 😊",
                errorMessage: "Arizani yuborishda xatolik yuz berdi!",
                warningMessage: "Iltimos, ismingiz va telefon raqamingizni kiriting!!!",
            }
        }
    },

};