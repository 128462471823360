import React from 'react';
import "./style/home-mobile.scss";
import {getText} from "../../locales";
import Fade from "react-reveal/Fade";
import FileImg from "../../asserts/images/header/file-img.png";
import FileKP from '../../asserts/file/focus-kp.pdf';
import Lottie from "react-lottie";
import animation from "../../asserts/loading/logo head animation";

const HomeMobile = () => {

    const options= {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animation
    };

    return (
        <div id="home" className="Home-mobile">
            <div className="container">
                <div className="text-box">

                        <Fade top>
                            <div className="top-text">
                                {getText("Home").topTitle1} <br/>
                                {getText("Home").topTitle2}
                            </div>
                        </Fade>

                        <Fade left>
                            <div className="center-logo">
                                <Lottie options={options} segments={[0, 0]} />
                            </div>
                        </Fade>

                        <Fade bottom>
                            <div className="button-box">
                                <a className="download-btn" href={FileKP} target="_blank">
                                    <div>{getText("Home").buttonText}</div>
                                    <img src={FileImg} alt=""/>
                                </a>
                            </div>
                        </Fade>
                </div>
            </div>
        </div>
    );
};

export default HomeMobile;