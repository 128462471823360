import React from 'react';
import "./style/clients-mobile.scss";
import {getText} from "../../locales";
import Fade from "react-reveal/Fade";
import Client5 from '../../asserts/images/clients/beehappy.png';
import Client7 from '../../asserts/images/clients/bek logo.png';
import Client9 from '../../asserts/images/clients/bestpharm.png';
import Client12 from '../../asserts/images/clients/cookme black.png';
import Client4 from '../../asserts/images/clients/crafers black.png';
import Client17 from '../../asserts/images/clients/daisy black.png';
import Client14 from '../../asserts/images/clients/davrbank.png';
import Client2 from '../../asserts/images/clients/egar.png';
import Client1 from '../../asserts/images/clients/grandpharm black.png';
import Client16 from '../../asserts/images/clients/hilol black.png';
import Client11 from '../../asserts/images/clients/invitris logo.png';
import Client20 from '../../asserts/images/clients/JA TASHKENT.png';
import Client18 from '../../asserts/images/clients/mirluxe.png';
import Client15 from '../../asserts/images/clients/mns.png';
import Client10 from '../../asserts/images/clients/pisa.png';
import Client19 from '../../asserts/images/clients/shamuk.png';
import Client3 from '../../asserts/images/clients/sharshara black.png';
import Client6 from '../../asserts/images/clients/sochnayadolina.png';
import Client8 from '../../asserts/images/clients/viko black.png';
import Client13 from '../../asserts/images/clients/xalq banki.png';

const ClientsMobile = () => {

    return (
        <div id="clients" className="Clients-mobile">

            <div className="container">
                <Fade bottom>
                    <div className="title">
                        {getText('Client').title1} <br/>
                        {getText('Client').title2}
                    </div>
                </Fade>
            </div>

            <div className="clients-box">

                <div className="img-box">

                    <a target="_blank" href="https://www.instagram.com/grandpharm.uz/">
                        <img src={Client1} alt=""/>
                    </a>

                    <a target="_blank" href="https://www.instagram.com/egar.uz/">
                        <img src={Client2} alt=""/>
                    </a>

                </div>

                <div className="img-box">

                    <a target="_blank" href="https://www.instagram.com/sharshara.uzbekistan/">
                        <img src={Client3} alt=""/>
                    </a>

                    <a target="_blank" href="https://www.instagram.com/crafersuz/">
                        <img src={Client4} alt=""/>
                    </a>

                </div>


                <div className="img-box">
                    <a href="https://www.instagram.com/bee_happy_uz/" target="_blank">
                        <img src={Client5} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/sochnaya_dolina/" target="_blank">
                        <img src={Client6} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="https://www.instagram.com/bekschool/" target="_blank">
                        <img src={Client7} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/viko.uz/" target="_blank">
                        <img src={Client8} alt=""/>
                    </a>
                </div>


                <div className="img-box">
                    <a href="https://www.instagram.com/bestpharm_uz/" target="_blank">
                        <img src={Client9} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/pisauniversity/" target="_blank">
                        <img src={Client10} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="https://www.instagram.com/slim.uz/" target="_blank">
                        <img src={Client11} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/cookme.uz/" target="_blank">
                        <img src={Client12} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="https://www.instagram.com/xalqbankinfo/" target="_blank">
                        <img src={Client13} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/davrbankuz/" target="_blank">
                        <img src={Client14} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="https://www.instagram.com/nms.uz/" target="_blank">
                        <img src={Client15} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/hilolmeat.uzb" target="_blank">
                        <img src={Client16} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="http://Instagram.com/daisy.uzb" target="_blank">
                        <img src={Client17} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/mirluxe_plaza/" target="_blank">
                        <img src={Client18} alt=""/>
                    </a>
                </div>

                <div className="img-box">
                    <a href="https://www.instagram.com/shamuk_homes/" target="_blank">
                        <img src={Client19} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/juventus.academy.tashkent/" target="_blank">
                        <img src={Client20} alt=""/>
                    </a>
                </div>

            </div>

        </div>
    );
};

export default ClientsMobile;