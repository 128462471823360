import React, {useEffect} from 'react';
import "./style/video-production-mobile.scss";
import Fade from 'react-reveal/Fade';
import {getText} from "../../locales";
import VideoImg from '../../asserts/images/video-production/video-img.png';
import Video from "../../asserts/video/video-production.mp4";

const VideoProductionMobile = () => {

    return (
        <div id="video-production" className="Video-production-mobile">
            <div className="container">
                <Fade left>
                    <div className="text-box">
                        <div className="title">
                            {getText("VideoProduction").title1} <br/>
                            {getText("VideoProduction").title2}
                        </div>
                        <div className="commit">
                            {getText("VideoProduction").commit1} <br/>
                            {getText("VideoProduction").commit2}
                        </div>
                        <a href="https://www.youtube.com/@IbrokhimJalilov" target="_blank">
                            <span>{getText("VideoProduction").buttonText}</span> <img src={VideoImg} alt=""/>
                        </a>
                    </div>
                </Fade>
            </div>

            <div className="video-box">
                <video width="100%" height="100%" preload="yes" playsinline={true} loop={true} autoPlay={true} muted={true}>
                    <source src={Video} type="video/mp4"/>
                </video>
            </div>
        </div>
    );
};

export default VideoProductionMobile;