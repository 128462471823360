import React from 'react';
import "./style/team-mobile.scss";
import {getText} from "../../locales";
import Fade from "react-reveal/Fade";
import Slider from "react-slick/lib";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Blur from "../../asserts/images/team/blur-team.webp";
import Team1 from "../../asserts/images/team/team-1.webp";
import Team2 from "../../asserts/images/team/team-2.webp";
import Team3 from "../../asserts/images/team/team-3.webp";
import Team4 from "../../asserts/images/team/team-4.webp";
import Team5 from "../../asserts/images/team/team-5.webp";
import Team6 from "../../asserts/images/team/team-6.webp";
import Team7 from "../../asserts/images/team/team-7.webp";
import Team8 from "../../asserts/images/team/team-8.webp";
import Team9 from "../../asserts/images/team/team-9.webp";
import Team10 from "../../asserts/images/team/team-10.webp";

const TeamMobile = () => {

    const teamData = [
        {
            name: getText('Team').team1.name,
            position: getText('Team').team1.position,
            img: Team1
        },
        {
            name: getText('Team').team2.name,
            position: getText('Team').team2.position,
            img: Team2
        },
        {
            name: getText('Team').team3.name,
            position: getText('Team').team3.position,
            img: Team3
        },
        {
            name: getText('Team').team4.name,
            position: getText('Team').team4.position,
            img: Team4
        },
        {
            name: getText('Team').team5.name,
            position: getText('Team').team5.position,
            img: Team5
        },
        {
            name: getText('Team').team6.name,
            position: getText('Team').team6.position,
            img: Team6
        },
        {
            name: getText('Team').team7.name,
            position: getText('Team').team7.position,
            img: Team7
        },
        {
            name: getText('Team').team8.name,
            position: getText('Team').team8.position,
            img: Team8
        },
        {
            name: getText('Team').team9.name,
            position: getText('Team').team9.position,
            img: Team9
        },
        {
            name: getText('Team').team10.name,
            position: getText('Team').team10.position,
            img: Team10
        }
    ];

    const settings2 = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "35px",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
    };

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        centerPadding: "35px",
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 1000,
    };

    return (
        <div id="team" className="Team-mobile">

                <div className="container">

                    <Fade bottom>
                        <div className="title">
                            {getText('Team').title1} <br/>
                            {getText('Team').title2}
                        </div>
                    </Fade>

                    <div className="carousel-box">
                        <Fade bottom>
                            <div className="carousel-box">
                                <Slider {...settings}>
                                    {
                                        teamData.map((item) => (
                                            <div className="Carousel-item">
                                                <div className="person-box">

                                                    <LazyLoadImage
                                                        delayTime={0}
                                                        effect={"blur"}
                                                        src={item.img}
                                                        width={item.img.width}
                                                        height={item.img.height}
                                                        className="img-fluid person-img"
                                                        placeholderSrc={Blur}
                                                    />

                                                    <div className="person-info">
                                                        <div className="name">
                                                            {item.name}
                                                        </div>
                                                        <div className="position">
                                                            {item.position}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Fade>
                    </div>

                </div>

        </div>
    );
};

export default TeamMobile;