export const RU = {

    VideoProduction: {
        title1: "Видео-",
        title2: "продакшн",
        commit1: "Реклама вашего продукта",
        commit2: "будет яркой и вовлекающей.",
        buttonText: "Смотреть больше"
    },

    Reviews: {
        pageTitle1: "ЧТО",
        pageTitle2: "ГОВОРЯТ",
        pageTitle3: "КЛИЕНТЫ",
        description1: "За короткий промежуток времени мы смогли увидеть реальные результаты с точки зрения производительности и осведомленности клиентов на местном рынке. Благодаря продуманной стратегии и созданию необходимого имиджа нашего банка, мы становимся все более популярными среди нашей целевой аудитории.",
        description2: "Это был замечательный опыт работы с командой FocusON Tech. Это очень профессиональные и доброжелательные люди, хорошо знающие свое дело. Я получил большую ценность для моего бизнеса, работая с этой компанией.",
        description3: "FocusON Tech был одним из самых компетентных и ответственных партнеров среди компаний, с которыми мы работали ранее в качестве сторонников BPO. У них отличное управление проектами и ориентация на результат."
    },

    Portfolio: {
        title: "Портфолио",
        modal: {
            title: "Средние показатели:",
            countTitle1: "Прирост подписчиков",
            countTitle2: "ER",
            countTitle3: "Охват",
            countTitle4: "Посещения профиля",
            countTitle5: "Комментарии",
            countTitle6: "Показы",
            link_text: "Перейти на аккаунт",
        },
        portfolioDescriptions: {
            description1: "Кондитерская/кофейня, расположенная в центре столицы. Пользуется популярностью как место встреч с демократичными ценами в меню. Цель - узнаваемость бренда, продвижение, увеличение продаж. Ведение проекта - более 2 лет.",
            description2: "Изготовление и реализация домашних полуфабрикатов быстрого приготовления. Цель - увеличение продаж, узнаваемость бренда. Ведение проекта - 6 месяцев.",
            description3: "Кондитерская фабрика. Цель — узнаваемость бренда, увеличить лояльность к нему. Работаем в течении 2х лет.",
            description4: "Cобственное производство и продажа женских трикотажных комплектов для дома, спорта и сна. Цель- узнаваемость бренда, увеличение продаж.",
            description5: "Сеть аптек GRANDPHARM одна из ведущих аптек в Узбекистане. Цель - узнаваемость бренда.",
            description6: "Производитель мясных деликатесов и колбасных изделий в Узбекистане.  Цель - узнаваемость бренда, увеличить лояльность к нему.",
            description7: "Производство пакетированных соков и нектаров. Цель - узнаваемость бренда. Ведение проекта -  4 месяца",
            description8: "Производство премиальных соков и нектаров ТМ VIKO в Узбекистане. Цель - узнаваемость бренда. Ведение проекта 4 месяца.",
            description9: "Прокат автомобилей, от эконом до премиум класса. Цель - узнаваемость бренда, увеличение продаж. Ведение проекта 2 года.",
            description10: "Pisa University - филиал международного итальянского университета в Ташкенте. Цель - узнаваемость бренд и привлечение студентов на бакалавр. Ведение проекта-8 месяцев.",
        }
    },

    Services: {
        title1: "МЫ",
        title2: "МОЖЕМ",
        buttonText: "Узнать больше",
        service1: {
            name: "Реклама в социальных сетях",
            description: "Предлагаем разработать эффективную рекламу в социальных сетях для вашей компании. Работаем непосредственно над узнаваемостью бренда на рынке."
        },
        service2: {
            name: "Разработка и запуск сайтов",
            description: "Разработка сайта с нуля по вашим пожеланиям, в индивидуальном исполнении и продуманный по вашим решениям. Мы предлагаем опытных разработчиков, дизайна, программирования, юзабилити и SEO-оптимизации."
        },
        service3: {
            name: "Разработка дизайна и персонального стиля",
            description: "Разработаем уникальное имя бренда, логотип, гайдлайн и брендбук - все, то, что сформирует «лицо» вашей компании на рынке."
        },
        service4: {
            name: "Создание роликов и фотосессий",
            description: "Создайте уникальный образ компании и привлекайте широкую аудиторию путем создания интерактивных роликов."
        }
    },

    About: {
        commit1: "С начала нашей деятельности мы успели поработать с более 100 местными и зарубежными компаниями.",
        commit2: "Наша миссия - помочь бизнесу получать большую прибыль и заработать безупречную репутацию, за счет повышения осведомленности потребителей о компании. Мы привлекаем веб-трафик и устанавливаем долгосрочные благополучные взаимосвязи с клиентами.",
        commit3: "Для каждого проекта выделяются определенные специалисты своего профиля. Правильно поставленная цель, а после и сформированная задача, являются залогом успешного продвижения проекта.",
        commit4: "Мы стараемся изучить бизнес изнутри, сделать анализ и спланировать дальнейшие действия.",
    },

    Home: {
        topTitle1: "МАРКЕТИНГОВОЕ",
        topTitle2: "АГЕНТСТВО",
        buttonText: "Скачать КП",
    },

    Navbar: {
        menu_link1: "О нас",
        menu_link2: "Услуги",
        menu_link9: "Видеопродакшн",
        menu_link3: "Команда",
        menu_link4: "Портфолио",
        menu_link5: "Наши клиенты",
        menu_link6: "Отзывы",
        menu_link7: "Контакты",
        menu_link8: "Оставить заявку",
        address1: "Узбекистан, Ташкент,",
        address2: "ул. Чуст, 1. Офис C-Space",
    },

    Team: {
        title1: "ГОТОВЫ",
        title2: "К БОЮ",
        team1: {
            name: "МУХАММАД АЛИ",
            position: "Шеф уровня 199",
        },
        team2: {
            name: "МУНИСА",
            position: "Гуру СММ",
        },
        team3: {
            name: "ДАМИР",
            position: "Арт-директор",
        },
        team4: {
            name: "ИБРАГИМ",
            position: "Мощный ВИДЕОГРАФ",
        },
        team5: {
            name: "МАДИНА",
            position: "SMM менеджер, а ещё и копирайтер",
        },
        team6: {
            name: "ЗУХРИДДИН",
            position: "Проект менеджер",
        },
        team7: {
            name: "Бунёд",
            position: "SMM менеджер",
        },
        team8: {
            name: "Тимур",
            position: "Таргетолог",
        },
        team9: {
            name: "Малика",
            position: "SMM менеджер",
        },
        team10: {
            name: "Бурхон",
            position: "Моушен-дизайнер",
        },
    },

    Client: {
        title1: "НАШИ",
        title2: "КЛИЕНТЫ",
    },

    Contact: {
        title1: "ЖДЕМ",
        title2: "ВАС",
        address: "Узбекистан, Ташкент, ул. Чуст, 1. Офис C-Space"
    },

    Application: {
        title: "Получите обратную связь",
        form: {
            title: "Есть вопросы?",
            name: {
                label: "Имя (обязательно)",
                placeholder: "Ваше имя",
            },
            phone: {
                label: "Телефон (обязательно)",
                placeholder: "+998 ( __ ) __ __ __",
            },
            description: {
                label: "Немного о вашей компании:",
                placeholder: "Опишите задачу, которую хотите*"
            },
            buttonText: "Отправить",
            toastify: {
                successMessage: "Ваша заявка успешно отправлена !!! 😊",
                errorMessage: "При отправке заявки произошла ошибка !!!",
                warningMessage: "Пожалуйста, введите Ваше имя и номер телефона!!!",
            }
        }
    },

};