import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "./pages/Home/Home";
import React, {useEffect, useState} from "react";
import {ToastContainer} from 'react-toastify';
import Loading from "./components/Loading/Loading";
import About from "./pages/About/About";
import Clients from "./pages/Clients/Clients";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Team from "./pages/Team/Team";
import Portfolio from "./pages/Portfolio/Portfolio";
import Reviews from "./pages/Reviews/Reviews";
import Application from "./pages/Application/Application";
import Mobile from "./pages/Mobile/Mobile";
import {getLang} from "./locales";
import VideoProduction from "./pages/VideoProduction/VideoProduction";

function App() {

    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{

        if (getLang() !== 'UZ'){
            document.title = "Маркетинговое агентство для вашего бизнеса."
        } else{
            document.title = "Biznesingiz uchun marketing agentligi."
        }

    },[]);

    useEffect(()=>{
        if (window.innerWidth <= 576){
            setIsMobile(true)
        } else{
            setIsMobile(false);
        }
    },[]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (

            <div className="App">

                    {
                        !isMobile ?

                            <Switch>
                                <Route exact path="/home" component={Home}/>
                                <Route exact path="/about" component={About}/>
                                <Route exact path="/services" component={Services}/>
                                <Route exact path="/video-production" component={VideoProduction}/>
                                <Route exact path="/team" component={Team}/>
                                <Route exact path="/portfolio" component={Portfolio}/>
                                <Route exact path="/clients" component={Clients}/>
                                <Route exact path="/reviews" component={Reviews}/>
                                <Route exact path="/contact" component={Contact}/>
                                <Route exact path="/application" component={Application}/>

                                <Route exact path="*">
                                    <Redirect to="/home"/>
                                </Route>

                            </Switch> :

                            <Switch>
                                <Route exact path="/" component={Mobile}/>

                                <Route exact path="*">
                                    <Redirect to="/"/>
                                </Route>
                            </Switch>

                    }


                <ToastContainer
                    theme="dark"
                    autoClose={2000}
                />

            </div>

    );
}

export default App;
