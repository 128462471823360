import React, {useState} from 'react';
import "../../components/Portfolio-card/portfolio-card.scss";
import PortfolioModal from "../../components/Portfolio-card/PortfolioModal";

const PortfolioCardMobile= ({portfolio}) => {

    const [open, setOpen] = useState(false);

    const openModal =()=>{
        setOpen(true);
    };

    const closeModal =()=>{
        setOpen(false);
    };

    return (
        <>
            <div className="Portfolio-card" onClick={()=>openModal()}>

                <img className="card-image" src={portfolio.portfolio_image} alt=""/>

                <div className="overline">
                    <img className="above-image" src={portfolio.above_image} alt=""/>

                    <div className="card-icon"></div>
                </div>
            </div>

            <PortfolioModal data={portfolio.modal} open={open} setOpen={setOpen} closeModal={closeModal}/>
        </>
    );
};

export default PortfolioCardMobile;