import React from 'react';
import "./style/reviews-mobile.scss";
import {getText} from "../../locales";
import Avatar1 from "../../asserts/images/reviews/Samadov A.jpg";
import Company1 from "../../asserts/images/reviews/davrbank.png";
import Avatar2 from "../../asserts/images/reviews/Nodirjon Akbarov.jpg";
import Company2 from "../../asserts/images/reviews/egar .png";
import Fade from "react-reveal/Fade";
import Slider from "react-slick/lib";
import Tirnoq from "../../asserts/images/reviews/tirnoq.png";
import Avatar3 from "../../asserts/images/reviews/diyor.png";
import Company3 from "../../asserts/images/reviews/crafers.png";

const ReviewsMobile = () => {

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000
    };

    const reviewData = [
        {
            name: "Dior Tolkiev",
            position: "Project manager",
            description: getText("Reviews").description3,
            image: Avatar3,
            company: Company3
        },
        {
            name: "Samadov Abdumajid",
            position: "Chairman of the Board",
            description: getText("Reviews").description1,
            image: Avatar1,
            company: Company1
        },
        {
            name: "Nodirjon Akbarov",
            position: "Founder",
            description: getText("Reviews").description2,
            image: Avatar2,
            company: Company2
        }
    ];

    return (
        <div id="reviews" className="Reviews-mobile">
            <div className="container">
                <Fade bottom>
                    <div className="title">
                        {getText("Reviews").pageTitle1} <br/>
                        {getText("Reviews").pageTitle2} <br/>
                        {getText("Reviews").pageTitle3}
                    </div>
                </Fade>

                <div className="feedback">
                    <div className="carousel-box">
                        <Slider {...settings}>

                            {
                                reviewData.map((item, index)=>(
                                    <div className="review">
                                        <div className="top">
                                            <div className="text-box">
                                                <div className="name">{item.name}</div>
                                                <div className="position">{item.position}</div>
                                            </div>

                                            <img src={Tirnoq} alt=""/>
                                        </div>
                                        <div className="description">
                                            {item.description}
                                        </div>

                                        <div className="img-box">
                                            <img className="avatar" src={item.image} alt=""/>
                                            <img className="company" src={item.company} alt=""/>
                                        </div>

                                    </div>
                                ))
                            }

                        </Slider>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ReviewsMobile;