import React from 'react';
import Fade from 'react-reveal/Fade';
import Layout from "../../components/Layout/Layout";
import "./application.scss";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import {toast} from "react-toastify";
import {getText} from "../../locales";

const Application = () => {

    const TOKEN = "6060469006:AAFpYLcWYjfow7upW56j_WrHenuZZEYrF7g";
    const CHAT_ID = "-1001834981023";
    const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    const onSubmit = (e) => {

        e.preventDefault();

        let message = `
         ✅ Заказ от сайта:
         
         👤 Имя:  ${e.target.name.value}
         📞 Номер телефона :  ${e.target.phone.value}    
         📝 Описание:  ${e.target.description.value}     
        `;

        let name = e.target.name.value.length > 0;
        let phone = e.target.phone.value.replace(/[^0-9]/g, '').length > 0;

        if ( name && phone){
            axios.post(URL_API, {
                chat_id: CHAT_ID,
                parse_mode: 'html',
                text: message,
            })

                .then((res) => {
                    e.target.name.value = "";
                    e.target.phone.value = "";
                    e.target.description.value = "";
                    toast.success(getText('Application').form.toastify.successMessage);
                })
                .catch((err) => {
                    toast.error(getText('Application').form.toastify.errorMessage);
                })
        } else{
            toast.warning(getText('Application').form.toastify.warningMessage);
        }

    };

    return (

        <Layout page="application">

                <Fade duration={0}>
                    <div className="Application">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="left-side">
                                        <div className="content">

                                            <Fade top>
                                                <div className="title">
                                                    {getText('Application').title}
                                                </div>
                                            </Fade>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">

                                    <Fade bottom>

                                        <div className="right-side">

                                            <div className="form-box">

                                                <div className="head-text">{getText('Application').form.title}</div>

                                                <form onSubmit={onSubmit}>

                                                <div className="input-box">
                                                    <label htmlFor="name">{getText('Application').form.name.label}</label> <br/>
                                                    <input id="name" name="name" type="text" placeholder={getText('Application').form.name.placeholder} className=""/>
                                                </div>

                                                <div className="input-box">
                                                    <label htmlFor="phone">{getText('Application').form.phone.label}</label> <br/>
                                                    <CurrencyFormat
                                                        id="phone"
                                                        name="phone"
                                                        format="+998 (##) ###-##-##" mask="_"
                                                        placeholder={getText('Application').form.phone.placeholder}
                                                    />
                                                </div>

                                                <div className="input-box">
                                                    <label htmlFor="description">{getText('Application').form.description.label}</label> <br/>
                                                    <textarea id="description" name="description" type="text" placeholder={getText('Application').form.description.placeholder} className=""/>
                                                </div>

                                                <button type="submit" className="form-btn">{getText('Application').form.buttonText}</button>

                                            </form>

                                            </div>
                                        </div>

                                    </Fade>

                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>

        </Layout>
    );
};

export default Application;