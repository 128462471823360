import React from 'react';
import animation from '../../asserts/loading/data.json';
import Lottie from 'react-lottie';
import "./Loading.scss";
import {use100vh} from "react-div-100vh";

const Loading = () => {

    const height = use100vh();

    const options= {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animation
    };

    return (
        <div className="Loading" style={{height: height}}>
            <Lottie options={options} segments={[0, 0]} />
        </div>
    );
};

export default Loading;