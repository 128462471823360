import React, {useEffect, useState} from 'react';
import "./about.scss";
import Layout from "../../components/Layout/Layout";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {useHistory} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {getLang, getText} from "../../locales";

const About = () => {

    return (
        <Layout page="about">

            <Fade>
                <div className="About">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">

                                <Fade bottom>
                                    <div className="left-text">

                                        {getLang() === "RU" || getLang() === null ?

                                            <>
                                                <div>О нас</div>
                                            </> :

                                            <>
                                                <div>Biz</div>
                                                <div>haqimizda</div>
                                            </>
                                        }


                                    </div>
                                </Fade>

                            </div>
                            <div className="col-md-6">

                                <Fade right>
                                    <div className="right-text-box">
                                        <div className="right-text">
                                            {getText("About").commit1}
                                            <br/> <br/>
                                            {getText("About").commit2}
                                            <br/> <br/>
                                            {getText("About").commit3}
                                            <br/> <br/>
                                            {getText("About").commit4}
                                        </div>
                                    </div>
                                </Fade>


                            </div>
                        </div>
                    </div>
                </div>
            </Fade>

        </Layout>
    );
};

export default About;