import React, {useEffect, useRef, useState} from 'react';
import "./services.scss";
import Layout from "../../components/Layout/Layout";
import Fade from 'react-reveal/Fade';
import Arrow from "../../asserts/images/services/arrow.svg";
import CurrencyFormat from "react-currency-format";
import 'react-alice-carousel/lib/alice-carousel.css';
import {getText} from "../../locales";
import axios from "axios";
import {toast} from "react-toastify";

const Services = () => {

    const [show, setShow] = useState(false);

    const openWindow = () => {
        setShow(true);
    };

    const closeWindow = () => {
        setShow(false);
    };

    const carouselData = [
        {
            status: "DIGITAL",
            title: getText("Services").service1.name,
            commit: getText("Services").service1.description,
        },
        {
            status: "WEB",
            title: getText("Services").service2.name,
            commit: getText("Services").service2.description,
        },
        {
            status: "DESIGN",
            title: getText("Services").service3.name,
            commit: getText("Services").service3.description,
        },
        {
            status: "PRODUCTION",
            title: getText("Services").service4.name,
            commit: getText("Services").service4.description,
        }
    ];

    const TOKEN = "6060469006:AAFpYLcWYjfow7upW56j_WrHenuZZEYrF7g";
    const CHAT_ID = "-1001834981023";
    const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    const onSubmit = (e) => {

        e.preventDefault();

        let message = `
         ✅ Заказ от сайта:
         
         👤 Имя:  ${e.target.name.value}
         📞 Номер телефона :  ${e.target.phone.value}    
         📝 Описание:  ${e.target.description.value}     
        `;

        let name = e.target.name.value.length > 0;
        let phone = e.target.phone.value.replace(/[^0-9]/g, '').length > 0;

        if ( name && phone){
            axios.post(URL_API, {
                chat_id: CHAT_ID,
                parse_mode: 'html',
                text: message,
            })

                .then((res) => {
                    e.target.name.value = "";
                    e.target.phone.value = "";
                    e.target.description.value = "";
                    toast.success(getText('Application').form.toastify.successMessage);
                })
                .catch((err) => {
                    toast.error(getText('Application').form.toastify.errorMessage);
                })
        } else{
            toast.warning(getText('Application').form.toastify.warningMessage);
        }

    };

    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return (
        <Layout page="services">
                <div className="Services">

                    <div className="desktop-services">
                        <div className="row">
                            <div className="col-md-5">

                                <Fade left>
                                    <div className="left-side">
                                        <div className="title">
                                            {getText("Services").title1} <br/>
                                            {getText("Services").title2}
                                        </div>
                                    </div>
                                </Fade>

                            </div>
                            <div className="col-md-7">

                                <div className="right-side">
                                    <div className="row">

                                        <Fade top>
                                            <div className="col-md-5">
                                                <div className="my-card">
                                                    <div className="card-header">
                                                        <div className="status-card">DIGITAL</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="title">
                                                            {getText("Services").service1.name}
                                                        </div>
                                                        <div className="commit">
                                                            {getText("Services").service1.description}
                                                        </div>

                                                        <div id="open-window-btn" className="button" onClick={openWindow}>
                                                            {getText("Services").buttonText} <img src={Arrow} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade right>
                                            <div className="col-md-5 offset-2">
                                                <div className="my-card">
                                                    <div className="card-header">
                                                        <div className="status-card">WEB</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="title">
                                                            {getText("Services").service2.name}
                                                        </div>
                                                        <div className="commit">
                                                            {getText("Services").service2.description}
                                                        </div>

                                                        <div className="button" onClick={openWindow}>
                                                            {getText("Services").buttonText} <img src={Arrow} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade left>
                                            <div className="col-md-5">
                                                <div className="my-card">
                                                    <div className="card-header">
                                                        <div className="status-card">DESIGN</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="title">
                                                            {getText("Services").service3.name}
                                                        </div>
                                                        <div className="commit">
                                                            {getText("Services").service3.description}
                                                        </div>

                                                        <div className="button" onClick={openWindow}>
                                                            {getText("Services").buttonText} <img src={Arrow} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade bottom>
                                            <div className="col-md-5 offset-2">
                                                <div className="my-card">
                                                    <div className="card-header">
                                                        <div className="status-card">Production</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="title">
                                                            {getText("Services").service4.name}
                                                        </div>
                                                        <div className="commit">
                                                            {getText("Services").service4.description}
                                                        </div>

                                                        <div className="button" onClick={openWindow}>
                                                            {getText("Services").buttonText} <img src={Arrow} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div ref={ref} className={`${show ? "window" : "window hide"}`}>

                        <div onClick={closeWindow} className="close-icon">

                        </div>

                        <div className="form-box">
                            <div className="head-text">{getText('Application').form.title}</div>

                            <form onSubmit={onSubmit}>

                                <div className="input-box">
                                    <label htmlFor="name">{getText('Application').form.name.label}</label> <br/>
                                    <input id="name" name="name" type="text" placeholder={getText('Application').form.name.placeholder} className=""/>
                                </div>

                                <div className="input-box">
                                    <label htmlFor="phone">{getText('Application').form.phone.label}</label> <br/>
                                    <CurrencyFormat
                                        id="phone"
                                        name="phone"
                                        format="+998 (##) ###-##-##" mask="_"
                                        placeholder={getText('Application').form.phone.placeholder}
                                    />
                                </div>

                                <div className="input-box">
                                    <label htmlFor="description">{getText('Application').form.description.label}</label> <br/>
                                    <textarea id="description" name="description" type="text" placeholder={getText('Application').form.description.placeholder} className=""/>
                                </div>

                                <button type="submit" className="form-btn">{getText('Application').form.buttonText}</button>

                            </form>

                        </div>

                    </div>

                </div>
        </Layout>
    );
};

export default Services;