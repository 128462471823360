import React, {useEffect, useRef, useState} from 'react';
import "./portfolio-card.scss";
import PortfolioModal from "./PortfolioModal";

const PortfolioCard = ({portfolio}) => {

    const [open, setOpen] = useState(false);

    const openModal =()=>{
      setOpen(true);
    };

    const closeModal =()=>{
        setOpen(false);
    };

    const state = useRef({ x: 0 });

    const handleMouseDown = e => {
        state.current.x = e.screenX;
    };

    const handleClick = e => {
        const delta = Math.abs(e.screenX - state.current.x);
        if (delta > 1) {
            e.preventDefault();
        } else{
            openModal();
        }
    };

    return (
        <>
            <div id="portfolio-card" onMouseDown={handleMouseDown} onClick={handleClick}  className="Portfolio-card">

                <img className="card-image" src={portfolio.portfolio_image} alt=""/>

                <div className="overline">
                    <img className="above-image" src={portfolio.above_image} alt=""/>

                    <div className="card-icon" onClick={()=>openModal()}></div>
                </div>
            </div>

            <PortfolioModal data={portfolio.modal} open={open} setOpen={setOpen} closeModal={closeModal}/>
        </>
    );
};

export default PortfolioCard;