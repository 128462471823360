import React from 'react';
import {Modal} from "antd";
import "./portfolio-modal.scss";
import {getText} from "../../locales";

const PortfolioModal = ({data, open, setOpen, closeModal}) => {
    return (

        <div className="Portfolio-modal">
            <Modal
                title={false}
                footer={null}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"100%"}
                closable={false}
            >

                <div className="row main-row">

                    <div className="col-md-7 p-0">
                        <div className="img-box">
                            <img className="" src={data.img} alt=""/>
                        </div>
                    </div>

                    <div className="col-md-5 p-0">
                        <div className="info-box">

                            <div className="close-icon" alt="" onClick={closeModal}/>

                            <div className="logo-box">
                                <img src={data.logo} alt=""/>
                            </div>
                            <div className="description">
                                {data.description}
                            </div>
                            <div className="count-box">
                                <div className="head-title">
                                    {getText("Portfolio").modal.title}
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle1}
                                            </div>
                                            <div className="number">
                                                {data.count.subscribers}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle2}
                                            </div>
                                            <div className="number">
                                                {data.count.er}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle3}
                                            </div>
                                            <div className="number">
                                                {data.count.coverage}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle4}
                                            </div>
                                            <div className="number">
                                                {data.count.visits}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle5}
                                            </div>
                                            <div className="number">
                                                {data.count.comment}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="count">
                                            <div className="title">
                                                {getText("Portfolio").modal.countTitle6}
                                            </div>
                                            <div className="number">
                                                {data.count.impressions}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="link-box d-flex align-items-center justify-content-center">
                                <a target="_blank" href={`${data?.link}`}>{getText("Portfolio").modal.link_text}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PortfolioModal;