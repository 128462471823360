import {SITE_LANGUAGE} from "../tools/constant";
import {UZ} from './UZ';
import {RU} from './RU';

export function getText(word) {
    if (localStorage.getItem(SITE_LANGUAGE)==="UZ"){
        return UZ[word];
    }
    else{
        return RU[word];
    }
}

export function getLang() {
    return localStorage.getItem(SITE_LANGUAGE);
}