import React, {useEffect, useRef, useState} from 'react';
import "./Layout.scss";
import Logo from "../../asserts/images/header/logo-head.png";
import Instagram from "../../asserts/images/header/instagram.png";
import Telegram from "../../asserts/images/header/telegram.png";
import Phone from "../../asserts/images/header/phone.png";
import Line from "../../asserts/images/header/left-line.png";
import TextRu from "../../asserts/images/header/text-ru.png";
import TextUz from "../../asserts/images/header/text-uz.png";
import LogoMobile from "../../asserts/images/header/logo-mobile.png";
import Arrow from "../../asserts/images/header/arrow-select.png";
import TelImg from "../../asserts/images/header/tel.png";
import {NavLink} from 'react-router-dom';
import {SITE_LANGUAGE} from "../../tools/constant";
import {getLang, getText} from "../../locales";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {useHistory} from 'react-router-dom';

const Layout = ({page, custom_class, children}) => {

    const history = useHistory();

    const [show, setShow] = useState();

    const [mouse, setMouse] = useState(localStorage.getItem("MOUSE") ? JSON.parse(localStorage.getItem("MOUSE")) : "isMouse");
    const [pause, setPause] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setPause(pause => false);
        }, 1000);
    }, []);

    const openMenu = () => {
        setShow(true);
    };

    const closeMenu = () => {
        setShow(false);
    };

    const [show_select, setShowSelect] = useState(0);

    const changeLang = (LANG) => {

        if (getLang() !== null || LANG !== "RU") {
            if (LANG !== localStorage.getItem(SITE_LANGUAGE)) {
                localStorage.setItem(SITE_LANGUAGE, LANG);
                window.location.reload();
            }
        }
    };

    const handleLanguage = () => {
        if (getLang() === "UZ") {
            changeLang("RU");
        } else {
            changeLang("UZ");
        }
    };

    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const downHandler = (e) => {

        let isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;

        if (isTouchPad) {
            // tailor UX for touch input
            localStorage.setItem("MOUSE", JSON.stringify("isTouchPad"));
        } else {
            // tailor UX for mouse input
            localStorage.setItem("MOUSE", JSON.stringify("isMouse"));
        }

        setTimeout(() => {
            switch (page) {

                case "home":
                    history.push("/about");
                    break;

                case "about":
                    history.push("/services");
                    break;

                case "services":
                    history.push("/team");
                    break;

                case "team":
                    history.push("/portfolio");
                    break;

                case "portfolio":
                    history.push("/video-production");
                    break;

                case "video-production":
                    history.push("/clients");
                    break;

                case "clients":
                    history.push("/reviews");
                    break;

                case "reviews":
                    history.push("/contact");
                    break;

                case "contact":
                    history.push("/application");
                    break;

                case "application":
                    history.push("/application");
                    break;
            }
        }, 0);

    };

    const upHandler = (e) => {

        let isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;
        // your code

        if (isTouchPad) {
            // tailor UX for touch input
            localStorage.setItem("MOUSE", JSON.stringify("isTouchPad"));
        } else {
            // tailor UX for mouse input
            localStorage.setItem("MOUSE", JSON.stringify("isMouse"));
        }

        setTimeout(() => {

            switch (page) {

                case "home":
                    history.push("/home");
                    break;

                case "about":
                    history.push("/home");
                    break;

                case "services":
                    history.push("/about");
                    break;

                case "team":
                    history.push("/services");
                    break;


                case "portfolio":
                    history.push("/team");
                    break;

                case "video-production":
                    history.push("/portfolio");
                    break;

                case "clients":
                    history.push("/video-production");
                    break;

                case "reviews":
                    history.push("/clients");
                    break;

                case "contact":
                    history.push("/reviews");
                    break;

                case "application":
                    history.push("/contact");
                    break;

                default:
                    break;
            }

        }, 0);
    };

    return (
        <ReactScrollWheelHandler
            upHandler={(event) => upHandler(event)}
            downHandler={(event) => downHandler(event)}
            timeout={0}/*
            pauseListeners={mouse !== "isMouse" ? pause : false}*/
            pauseListeners={pause}
            disableSwipeWithMouse={true}
        >
            <div className="Layout">

                <div className="Navbar">

                    <div className="desktop-navbar">
                        <div className="content">
                            <NavLink to="/home" className="left-side">
                                <img src={Logo} alt=""/>
                            </NavLink>
                            <div className="right-side">

                                <div className="custom-language-box">
                                    <div className="head" onClick={() => setShowSelect(!show_select)}>
                                        <div className="lang-text">
                                            {
                                                getLang() === "UZ" ? "Uz" : "Ру"
                                            }
                                        </div>
                                        <img className={`${show_select ? "rotate-img" : ""}`} src={Arrow} alt=""/>
                                    </div>

                                    <div onClick={() => {
                                        setShowSelect(false);
                                        handleLanguage()
                                    }} className={`${show_select ? "bottom" : "hide-bottom"}`}>
                                        {getLang() === "UZ" ?

                                            <div>Ру</div> : <div>Uz</div>
                                        }
                                    </div>

                                </div>

                                <div onClick={openMenu} className="show-menu-icon"></div>

                            </div>
                        </div>
                    </div>

                    <div className="mobile-navbar">
                        <div className="container">
                            <div className="content">
                                <NavLink to="/home" className="left-side">
                                    <img src={LogoMobile} alt=""/>
                                </NavLink>
                                <div className="right-side">
                                    <div className="custom-language-box">
                                        <div className="head" onClick={() => setShowSelect(!show_select)}>
                                            <div className="lang-text">
                                                {
                                                    getLang() === "UZ" ? "Uz" : "Ру"
                                                }
                                            </div>
                                            <img className={`${show_select ? "rotate-img" : ""}`} src={Arrow} alt=""/>
                                        </div>

                                        <div onClick={() => {
                                            setShowSelect(false);
                                            handleLanguage()
                                        }} className={`${show_select ? "bottom" : "hide-bottom"}`}>
                                            {getLang() === "UZ" ?

                                                <div>Ру</div> : <div>Uz</div>
                                            }
                                        </div>

                                    </div>
                                    <div onClick={openMenu} className="show-menu-icon"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={`${custom_class} contact-box`}>

                    <div className={`${custom_class} content`}>
                        <a target="_blank" href="https://instagram.com/focuson.uz">
                            <img src={Instagram} alt=""/>
                        </a>
                        <a target="_blank" href="https://t.me/nads_admin">
                            <img src={Telegram} alt=""/>
                        </a>
                        <a target="_blank" href="tel:+998935009000">
                            <img src={Phone} alt=""/>
                        </a>
                        <img className="line" src={Line} alt=""/>

                        {
                            getLang() === "UZ" ? <img src={TextUz} alt=""/> : <img src={TextRu} alt=""/>
                        }

                    </div>

                </div>

                <div className="link-dots">
                    <NavLink activeClassName="active" to="/home" className="link-item">
                        <div className="dot">

                        </div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/about" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/services" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/team" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/portfolio" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/video-production" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/clients" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/reviews" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/contact" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                    <NavLink activeClassName="active" to="/application" className="link-item">
                        <div className="dot"></div>
                    </NavLink>
                </div>

                <div ref={ref} className={`${show ? "menu-box" : "menu-box hide"}`}>

                    <div className="menu-contact-box">
                        <a target="_blank" href="https://instagram.com/focuson.uz">
                            <img src={Instagram} alt=""/>
                        </a>
                        <a target="_blank" href="https://t.me/nads_admin">
                            <img src={Telegram} alt=""/>
                        </a>
                        <a target="_blank" href="tel:+998935009000">
                            <img src={Phone} alt=""/>
                        </a>
                        <img className="line" src={Line} alt=""/>
                        {
                            getLang() === "UZ" ? <img src={TextUz} alt=""/> : <img src={TextRu} alt=""/>
                        }
                    </div>

                    <div className="info-box">
                        <div className="side-name">
                            hello@focuson.uz
                        </div>
                        <div className="address">
                            {getText("Navbar").address1} <br/>
                            {getText("Navbar").address2}
                        </div>
                        <a className="phone" href="tel:+998935009000">+998 (93) 500 90 00</a>
                    </div>

                    <div onClick={closeMenu} className="close-icon"></div>

                    <NavLink to="/home">
                        <img src={LogoMobile} className="logo-mobile" alt=""/>
                    </NavLink>

                    <div className="row">
                        <div className="col-md-7">
                            <ul className="desktop-menu">
                                <li>
                                    <NavLink activeClassName="active" to="/about"
                                             onClick={closeMenu}>{getText("Navbar").menu_link1}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/services"
                                             onClick={closeMenu}>{getText("Navbar").menu_link2}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/team"
                                             onClick={closeMenu}>{getText("Navbar").menu_link3}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/portfolio"
                                             onClick={closeMenu}>{getText("Navbar").menu_link4}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/video-production"
                                             onClick={closeMenu}>{getText("Navbar").menu_link9}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/clients"
                                             onClick={closeMenu}>{getText("Navbar").menu_link5}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/reviews"
                                             onClick={closeMenu}>{getText("Navbar").menu_link6}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/contact"
                                             onClick={closeMenu}>{getText("Navbar").menu_link7}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/application"
                                             onClick={closeMenu}>{getText("Navbar").menu_link8}</NavLink>
                                </li>
                            </ul>
                            <ul className="mobile-menu">
                                <li>
                                    <a href="#about" onClick={() => closeMenu()}>{getText("Navbar").menu_link1}</a>
                                </li>
                                <li>
                                    <a href="#services" onClick={() => closeMenu()}>{getText("Navbar").menu_link2}</a>
                                </li>
                                <li>
                                    <a href="#team" onClick={() => closeMenu()}>{getText("Navbar").menu_link3}</a>
                                </li>
                                <li>
                                    <a href="#portfolio" onClick={closeMenu}>{getText("Navbar").menu_link4}</a>
                                </li>
                                <li>
                                    <a href="#video-production"
                                       onClick={() => closeMenu()}>{getText("Navbar").menu_link9}</a>
                                </li>
                                <li>
                                    <a href="#clients" onClick={closeMenu}>{getText("Navbar").menu_link5}</a>
                                </li>
                                <li>
                                    <a href="#reviews" onClick={closeMenu}>{getText("Navbar").menu_link6}</a>
                                </li>
                                <li>
                                    <a href="#contact" onClick={closeMenu}>{getText("Navbar").menu_link7}</a>
                                </li>
                                <li>
                                    <a href="#application" onClick={closeMenu}>{getText("Navbar").menu_link8}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5">
                            <div className="right">
                                <div className="side-name">
                                    hello@focuson.uz
                                </div>
                                <div className="address">
                                    {getText("Navbar").address1} <br/>
                                    {getText("Navbar").address2}
                                </div>
                                <a className="phone" href="tel:+998935009000">+998 (93) 500 90 00</a>
                            </div>
                        </div>
                    </div>

                </div>

                <a className="phone-box" href="tel:+998935009000"><img src={TelImg} alt=""/></a>

                <div className="main">
                    {children}
                </div>
            </div>

        </ReactScrollWheelHandler>

    );
};

export default Layout;