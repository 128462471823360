import React, {useEffect, useState} from 'react';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Fade from "react-reveal/Fade";
import Layout from "../../components/Layout/Layout";
import {useHistory} from 'react-router-dom';
import PortfolioImg1 from '../../asserts/images/portfolio/beehappycover.webp';
import PortfolioImg2 from '../../asserts/images/portfolio/cookmecover.webp';
import PortfolioImg3 from '../../asserts/images/portfolio/craferscover.webp';
import PortfolioImg4 from '../../asserts/images/portfolio/daisycover.webp';
import PortfolioImg5 from '../../asserts/images/portfolio/grand-video.webp';
import PortfolioImg6 from '../../asserts/images/portfolio/sharsharacover.webp';
import PortfolioImg7 from '../../asserts/images/portfolio/sochnayadolinacover.webp';
import PortfolioImg8 from '../../asserts/images/portfolio/vikocover.webp';
import PortfolioImg9 from '../../asserts/images/portfolio/egar-video.webp';
import PortfolioImg10 from '../../asserts/images/portfolio/pisa-video.webp';
import PortfolioAboveImg1 from '../../asserts/images/portfolio/beehappy.png';
import PortfolioAboveImg2 from '../../asserts/images/portfolio/cookme white.png';
import PortfolioAboveImg3 from '../../asserts/images/portfolio/crafers white.png';
import PortfolioAboveImg4 from '../../asserts/images/portfolio/daisy white.png';
import PortfolioAboveImg5 from '../../asserts/images/portfolio/grandpharm white.png';
import PortfolioAboveImg6 from '../../asserts/images/portfolio/sharshara white.png';
import PortfolioAboveImg7 from '../../asserts/images/portfolio/sochnayadolina.png';
import PortfolioAboveImg8 from '../../asserts/images/portfolio/viko white.png';
import PortfolioAboveImg9 from '../../asserts/images/portfolio/egar-white.png';
import PortfolioAboveImg10 from '../../asserts/images/portfolio/pisa white.png';
import PortfolioModalImg1 from '../../asserts/images/portfolio/beehappy.webp';
import PortfolioModalImg2 from '../../asserts/images/portfolio/cookme.webp';
import PortfolioModalImg3 from '../../asserts/images/portfolio/crafers.webp';
import PortfolioModalImg4 from '../../asserts/images/portfolio/daisy.webp';
import PortfolioModalImg5 from '../../asserts/images/portfolio/grandpharm.webp';
import PortfolioModalImg6 from '../../asserts/images/portfolio/sharshara.webp';
import PortfolioModalImg7 from '../../asserts/images/portfolio/sochnayadolina.webp';
import PortfolioModalImg8 from '../../asserts/images/portfolio/viko.webp';
import PortfolioModalImg9 from '../../asserts/images/portfolio/egar.webp';
import PortfolioModalImg10 from '../../asserts/images/portfolio/pisa.webp';
import PortfolioCard from "../../components/Portfolio-card/PortfolioCard";
import "./portfolio.scss";
import {getText} from "../../locales";
import Slider from "react-slick";

const Portfolio = () => {

    const portfolioData = [
        {
            portfolio_image: PortfolioImg5,
            above_image: PortfolioAboveImg5,
            modal: {
                img: PortfolioModalImg5,
                logo: PortfolioAboveImg5,
                description: getText("Portfolio").portfolioDescriptions.description5,
                count: {
                    subscribers: "5 000",
                    er: "7 %",
                    coverage: "700 000",
                    visits: "700",
                    comment: "6 700",
                    impressions: "800 000"
                },
                link: "https://instagram.com/grandpharm.uz",
            }
        },
        {
            portfolio_image: PortfolioImg1,
            above_image: PortfolioAboveImg1,
            modal: {
                logo: PortfolioAboveImg1,
                img: PortfolioModalImg1,
                description: getText("Portfolio").portfolioDescriptions.description1,
                count: {
                    subscribers: "560",
                    er: "4.28 %",
                    coverage: "187 000",
                    visits: "10 517",
                    comment: "50",
                    impressions: "550"
                },
                link: "https://instagram.com/bee_happy_uz",
            }
        },
        {
            portfolio_image: PortfolioImg9,
            above_image: PortfolioAboveImg9,
            modal: {
                img: PortfolioModalImg9,
                logo: PortfolioAboveImg9,
                description: getText("Portfolio").portfolioDescriptions.description9,
                count: {
                    subscribers: "90 000",
                    er: "7 %",
                    coverage: "30 000",
                    visits: "2 500",
                    comment: "100",
                    impressions: "48 000"
                },
                link: "https://www.instagram.com/egar.uz/",
            }
        },
        {
            portfolio_image: PortfolioImg7,
            above_image: PortfolioAboveImg7,
            modal: {
                img: PortfolioModalImg7,
                logo: PortfolioAboveImg7,
                description: getText("Portfolio").portfolioDescriptions.description7,
                count: {
                    subscribers: "1 717",
                    er: "11.6 %",
                    coverage: "371 058",
                    visits: "4 365",
                    comment: "5 500",
                    impressions: "742 507"
                },
                link: "http://Instagram.com/sochnaya_dolina",
            }
        },
        {
            portfolio_image: PortfolioImg6,
            above_image: PortfolioAboveImg6,
            modal: {
                img: PortfolioModalImg6,
                logo: PortfolioAboveImg6,
                description: getText("Portfolio").portfolioDescriptions.description6,
                count: {
                    subscribers: "1 530",
                    er: "3.13 %",
                    coverage: "1 620 500",
                    visits: "9 000",
                    comment: "40",
                    impressions: "1 950 000"
                },
                link: "http://Instagram.com/sharshara.uzbekistan",
            }
        },
        {
            portfolio_image: PortfolioImg2,
            above_image: PortfolioAboveImg2,
            modal: {
                img: PortfolioModalImg2,
                logo: PortfolioAboveImg2,
                description: getText("Portfolio").portfolioDescriptions.description2,
                count: {
                    subscribers: "2 700",
                    er: "4.71 %",
                    coverage: "300 000",
                    visits: "19 700",
                    comment: "60",
                    impressions: "1 034 700"
                },
                link: "https://instagram.com/cookme.uz",
            }
        },
        {
            portfolio_image: PortfolioImg3,
            above_image: PortfolioAboveImg3,
            modal: {
                img: PortfolioModalImg3,
                logo: PortfolioAboveImg3,
                description: getText("Portfolio").portfolioDescriptions.description3,
                count: {
                    subscribers: "3 000",
                    er: "1.57 %",
                    coverage: "736 000",
                    visits: "3 850",
                    comment: "40",
                    impressions: "783 000"
                },
                link: "http://Instagram.com/crafersuz",
            }
        },
        {
            portfolio_image: PortfolioImg8,
            above_image: PortfolioAboveImg8,
            modal: {
                img: PortfolioModalImg8,
                logo: PortfolioAboveImg8,
                description: getText("Portfolio").portfolioDescriptions.description8,
                count: {
                    subscribers: "3 000",
                    er: "9.44 %",
                    coverage: "178 000",
                    visits: "4 000",
                    comment: "5 500",
                    impressions: "380 000"
                },
                link: "http://Instagram.com/viko.uz",
            }
        },
        {
            portfolio_image: PortfolioImg10,
            above_image: PortfolioAboveImg10,
            modal: {
                img: PortfolioModalImg10,
                logo: PortfolioAboveImg10,
                description: getText("Portfolio").portfolioDescriptions.description10,
                count: {
                    subscribers: "1 189",
                    er: "3.77 %",
                    coverage: "260 240",
                    visits: "3 455",
                    comment: "10",
                    impressions: "744 589"
                },
                link: "https://www.instagram.com/pisauniversity/",
            }
        },
        {
            portfolio_image: PortfolioImg4,
            above_image: PortfolioAboveImg4,
            modal: {
                img: PortfolioModalImg4,
                logo: PortfolioAboveImg4,
                description: getText("Portfolio").portfolioDescriptions.description4,
                count: {
                    subscribers: "1 250",
                    er: "4.16 %",
                    coverage: "190 750",
                    visits: "6 760",
                    comment: "50",
                    impressions: "254 000"
                },
                link: "http://Instagram.com/daisy.uzb",
            }
        },
    ];

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        pauseOnHover: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 500,
    };

    return (
        <Layout page="portfolio" custom_class="contact-box-bg">
                <Fade duration={0}>
                    <div className="Portfolio">

                        <Slider {...settings}>

                            <div className="Carousel-item">
                                <PortfolioCard portfolio={portfolioData[0]}/>
                                <PortfolioCard portfolio={portfolioData[1]}/>
                            </div>

                            <div className="Carousel-item">
                                <PortfolioCard portfolio={portfolioData[2]}/>
                                <PortfolioCard portfolio={portfolioData[3]}/>
                            </div>

                            <div className="Carousel-item">
                                <PortfolioCard portfolio={portfolioData[4]}/>
                                <PortfolioCard portfolio={portfolioData[5]}/>
                            </div>

                            <div className="Carousel-item">
                                <PortfolioCard portfolio={portfolioData[6]}/>
                                <PortfolioCard portfolio={portfolioData[7]}/>
                            </div>

                            <div className="Carousel-item">
                                <PortfolioCard portfolio={portfolioData[8]}/>
                                <PortfolioCard portfolio={portfolioData[9]}/>
                            </div>

                        </Slider>

                    </div>
                </Fade>
        </Layout>
    );
};

export default Portfolio;