import React from 'react';
import "./style/about-mobile.scss"
import Fade from "react-reveal/Fade";
import {getLang, getText} from "../../locales";

const AboutMobile = () => {
    return (
        <div id="about" className="About-mobile">
            <div className="container">

                <Fade left>
                    <div className="title">

                        {getLang() === "RU" || getLang() === null ?

                            <>
                                <div>O НАС</div>
                            </> :

                            <>
                                <div>Biz </div>
                                <div>haqimizda</div>
                            </>

                        }

                    </div>
                </Fade>

                <Fade right>
                    <div className="commit-box">
                        <div className="commit">
                            {getText("About").commit1}
                            <br/> <br/>
                            {getText("About").commit2}
                            <br/> <br/>
                            {getText("About").commit3}
                            <br/> <br/>
                            {getText("About").commit4}
                        </div>
                    </div>
                </Fade>

            </div>
        </div>
    );
};

export default AboutMobile;