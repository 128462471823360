import React, {useEffect, useState} from 'react';
import "./home.scss";
import 'react-alice-carousel/lib/alice-carousel.css';
import Layout from "../../components/Layout/Layout";
import Fade from 'react-reveal/Fade';
import {getText} from "../../locales";
import FileImg from '../../asserts/images/header/file-img.png';
import FileKP from '../../asserts/file/focus-kp.pdf';

import Lottie from 'react-lottie';
import animation from '../../asserts/loading/logo head animation.json';

const Home = () => {

    const options= {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animation
    };

    return (
        <Layout page="home">

                    <Fade duration={500}>
                        <div className="Home">

                            <div className="container">

                                <div className="content">

                                    <Fade top>
                                        <div className="top-text">
                                            {getText("Home").topTitle1} <br/>
                                            {getText("Home").topTitle2}
                                        </div>
                                    </Fade>

                                    <Fade left>
                                        <div className="center-logo">
                                            {/*<img src={LogoHome} alt=""/>*/}

                                            <Lottie options={options} segments={[0, 0]} />

                                        </div>
                                    </Fade>

                                    <Fade bottom>

                                        <div className="button-box">
                                            <a className="download-btn" href={FileKP} target="_blank">
                                                <div>{getText("Home").buttonText}</div>
                                                <img src={FileImg} alt=""/>
                                            </a>

                                        </div>

                                    </Fade>
                                </div>

                            </div>

                        </div>
                    </Fade>

        </Layout>
    );
};

export default Home;