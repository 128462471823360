import React, {useEffect, useState} from 'react';
import "./team.scss";
import Layout from "../../components/Layout/Layout";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {useHistory} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Team1 from "../../asserts/images/team/team-1.webp";
import Team2 from "../../asserts/images/team/team-2.webp";
import Team3 from "../../asserts/images/team/team-3.webp";
import Team4 from "../../asserts/images/team/team-4.webp";
import Team5 from "../../asserts/images/team/team-5.webp";
import Team6 from "../../asserts/images/team/team-6.webp";
import Team7 from "../../asserts/images/team/team-7.webp";
import Team8 from "../../asserts/images/team/team-8.webp";
import Team9 from "../../asserts/images/team/team-9.webp";
import Team10 from "../../asserts/images/team/team-10.webp";
import Blur from "../../asserts/images/team/blur-team.webp";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getText} from "../../locales";
import Slider from "react-slick/lib";

const Team = () => {

    const teamData = [
        {
            name: getText('Team').team1.name,
            position: getText('Team').team1.position,
            img: Team1
        },
        {
            name: getText('Team').team2.name,
            position: getText('Team').team2.position,
            img: Team2
        },
        {
            name: getText('Team').team3.name,
            position: getText('Team').team3.position,
            img: Team3
        },
        {
            name: getText('Team').team4.name,
            position: getText('Team').team4.position,
            img: Team4
        },
        {
            name: getText('Team').team5.name,
            position: getText('Team').team5.position,
            img: Team5
        },
        {
            name: getText('Team').team6.name,
            position: getText('Team').team6.position,
            img: Team6
        },
        {
            name: getText('Team').team7.name,
            position: getText('Team').team7.position,
            img: Team7
        },
        {
            name: getText('Team').team8.name,
            position: getText('Team').team8.position,
            img: Team8
        },
        {
            name: getText('Team').team9.name,
            position: getText('Team').team9.position,
            img: Team9
        },
        {
            name: getText('Team').team10.name,
            position: getText('Team').team10.position,
            img: Team10
        }
    ];

    // react-slick

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
    };

    return (
        <Layout page="team">
                <Fade>
                    <div className="Team">

                        <div className="desktop-team">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5">

                                        <Fade left>
                                            <div className="left-side">
                                                <div className="title">
                                                    {getText('Team').title1} <br/>
                                                    {getText('Team').title2}
                                                </div>
                                            </div>
                                        </Fade>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="right-side">

                                            <div className="carousel-box">
                                                <Slider {...settings}>
                                                    <div className="Carousel-item">

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[0].img}
                                                                width={teamData[0].img.width}
                                                                height={teamData[0].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[0].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[0].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[3].img}
                                                                width={teamData[3].img.width}
                                                                height={teamData[3].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[3].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[3].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="Carousel-item">

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[1].img}
                                                                width={teamData[1].img.width}
                                                                height={teamData[1].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[1].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[1].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[4].img}
                                                                width={teamData[4].img.width}
                                                                height={teamData[4].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[4].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[4].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="Carousel-item">

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[2].img}
                                                                width={teamData[2].img.width}
                                                                height={teamData[2].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[2].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[2].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[5].img}
                                                                width={teamData[5].img.width}
                                                                height={teamData[5].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[5].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[5].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="Carousel-item">

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[6].img}
                                                                width={teamData[6].img.width}
                                                                height={teamData[6].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[6].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[6].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[7].img}
                                                                width={teamData[7].img.width}
                                                                height={teamData[7].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[7].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[7].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="Carousel-item">

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[8].img}
                                                                width={teamData[8].img.width}
                                                                height={teamData[8].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[8].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[8].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="person-box">

                                                            <LazyLoadImage
                                                                delayTime={0}
                                                                effect={"blur"}
                                                                src={teamData[9].img}
                                                                width={teamData[9].img.width}
                                                                height={teamData[9].img.height}
                                                                className="img-fluid person-img"
                                                                placeholderSrc={Blur}
                                                            />

                                                            <div className="person-info">
                                                                <div className="name">
                                                                    {teamData[9].name}
                                                                </div>
                                                                <div className="position">
                                                                    {teamData[9].position}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Slider>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fade>
        </Layout>
    );
};

export default Team;