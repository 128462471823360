import React from 'react';
import Fade from "react-reveal/Fade";
import {getText} from "../../locales";
import "./style/contact-mobile.scss";

const ContactMobile = () => {
    return (
        <div id="contact" className="Contact-mobile">
            <div className="container">
                <div className="content">
                    <Fade left>
                        <div className="title">
                            {getText('Contact').title1} <br/>
                            {getText('Contact').title2}
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className="site-name">hello@focuson.uz</div>
                        <div className="address">{getText('Contact').address}</div>
                        <a className="phone" href="tel:+998935009000">+998 (93) 500 90 00</a>
                    </Fade>
                </div>
            </div>

            <Fade right>
                <div className="map-box">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.021671643538!2d69.33057541492526!3d41.33014210750583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef505912ee33f%3A0xe98cd0574a0284c!2zQy1TcGFjZSAo0LrQvtCy0L7RgNC60LjQvdCzINGG0LXQvdGC0YAp!5e0!3m2!1sru!2s!4v1676551531368!5m2!1sru!2s"
                        width="600" allowFullScreen=""
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                </div>
            </Fade>
        </div>
    );
};

export default ContactMobile;