import React, {useEffect, useState} from 'react';
import "./video-production.scss";
import Layout from "../../components/Layout/Layout";
import Fade from 'react-reveal/Fade';
import {getText} from "../../locales";
import VideoImg from '../../asserts/images/video-production/video-img.png';
import Video from "../../asserts/video/video-production.mp4";

const VideoProduction = () => {

    return (
        <Layout page="video-production">

                <Fade>
                    <div className="Video-production">
                            <div className="row">
                                <div className="col-md-6 p-0">
                                    <Fade bottom>
                                        <div className="left-side">

                                            <div>
                                                <div className="title">
                                                    {getText("VideoProduction").title1} <br/>
                                                    {getText("VideoProduction").title2}
                                                </div>

                                                <div className="commit">
                                                    {getText("VideoProduction").commit1} <br/>
                                                    {getText("VideoProduction").commit2}
                                                </div>

                                                <a href="https://www.youtube.com/@IbrokhimJalilov" target="_blank">
                                                    <span>{getText("VideoProduction").buttonText}</span> <img src={VideoImg} alt=""/>
                                                </a>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="right-side">
                                        <video width="100%" height="100%" preload="yes" playsinline={true} autoPlay={true} loop={true} muted={true}>
                                            <source src={Video} type="video/mp4"/>
                                            К сожалению, ваш браузер не поддерживает встроенные видео.
                                        </video>
                                    </div>
                                </div>
                            </div>
                    </div>
                </Fade>

        </Layout>
    );
};

export default VideoProduction;